.rahmen {
	border: 1px solid red;
	height: 150px;
}

.rahmen > img {
	float: left;
	width: 200px;
}

