.rahmen {
	background: lightblue;
	border: 1px solid blue;
	padding: 5px;
	border-radius:5px;
	display: inline-block;
}

.aktieeigenschaft {
	line-height: 1;
}

blau {
	color: blue;
}

gruen {
	color: green;
}

