@media only screen and (min-width: 390px)  and (orientation: portrait) {
	/* IPhone 13 Ausrichtung Hochkant */ 
	.navbar {
		width:390px;
		overflow: clip;
	}
}
@media only screen and (min-width: 844px)  and (orientation: landscape) {
	/* IPhone 13 Ausrichtung Quer */ 
	.navbar {
		width:844px;
		overflow: clip;
	}
}

.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 300px;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}
