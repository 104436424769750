.scrollbarDeepPurple::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.scrollbarDeepPurple::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scrollbarDeepPurple::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #512da8;
}

.scrollbarDeepPurple {
    scrollbar-color: #512da8 #F5F5F5;
}

.borderedDeepPurple::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border: 1px solid #512da8;
}

.borderedDeepPurple::-webkit-scrollbar-thumb {
    -webkit-box-shadow: none;
}

.thin::-webkit-scrollbar {
    width: 6px;
}

.scrollEins {
    position: relative;
    overflow-y: scroll;
    max-height: 400px;
}

