.aktieeigenschaft{
	line-height:1em;
	color:blue;
}

.aktieeigenschaft > label{
	color:black;
	width:150px;
	overflow:clip;
}
